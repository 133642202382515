import React from "react";
import { CircleLoader } from "react-spinners";
import BkModifyButton from "../layout/BkModifyButton";
import { EnumSelect } from "../EnumSelect";
import { BoatOrPlaceSize } from "../layout/BoatOrPlaceSize";
import { genericPut } from "../../services/axiosApi";
import swal from "sweetalert";
import { SeasonSelect } from "../SeasonSelect";

const DataX = props => {
  const { t, edit, title, data, cb, editor } = props;
  const d = [];
  d.push(<th>{t(title)}</th>);
  if (!edit) {
    d.push(<td>{data}</td>);
  } else if (!editor) {
    d.push(
      <td>
        <input value={data} onChange={e => cb(e.target.value)}></input>
      </td>
    );
  } else {
    d.push(<td>{editor}</td>);
  }
  return d;
};

const Data = props => {
  const { t, edit, title, data, obj, property, cb, editor, float } = props;
  const d = [];
  d.push(<th>{t(title)}</th>);
  if (!edit) {
    d.push(<td>{obj[property]}</td>);
  } else if (!editor) {
    d.push(
      <td>
        <input
          value={obj[property]}
          onChange={e => {
            if (float) {
              obj[property] = e.target.value.replace(",", ".");
            } else {
              obj[property] = e.target.value;
            }
            cb(data);
          }}
        ></input>
      </td>
    );
  } else {
    d.push(<td>{editor}</td>);
  }
  return d;
};

const LanguageSelect = props => {
  const { l, cb, selected } = props;
  return (
    <EnumSelect location={l} cb={cb} enumType="language" selected={selected} />
  );
}

const BoatTypeSelect = props => {
  const { l, cb, selected } = props;
  return (
    <EnumSelect location={l} cb={cb} enumType="BoatType" selected={selected} />
  );
};

const PlaceTypeSelect = props => {
  const { l, cb, selected } = props;
  return (
    <EnumSelect
      location={l}
      cb={cb}
      enumType="PlaceType"
      selected={selected}
      filter={et => et.startsWith("BB_") || et == "WAREHOUSE" || et == "ICE" || et == "KEY" || et == "SERVICE" }
    />
  );
};

const ProductTypeSelect = props => {
  const { l, cb, selected, addOn } = props;
  return (
    <EnumSelect
      location={l}
      cb={cb}
      enumType="ProductType"
      selected={selected}
      filter={et =>
        (!addOn &&
          (et === "PLACE_GUEST" ||
            et === "PLACE_SEASON" ||
            et === "PLACE_SHARE")) ||
        (addOn &&
          (et === "PLACE_SEASON_ADDED" || 
           et === "PLACE_SEASON_ADDITIONAL" ||
           et === "SEASON_EXTRA"))
      }
    />
  );
};

const SubareaSelect = props => {
  const { subareas, cb, selected, multiple } = props;
  if (!subareas || !Array.isArray(subareas) || subareas.length === 0) {
    console.log("No subareas: " + subareas);
    return "";
  }
  if (!selected) {
    cb(subareas[0].id);
  }
  const vals = [];
  subareas.forEach(sa => {
    if (selected === sa.id) {
      vals.push(
        <option value={sa.id} selected="selected">
          {sa.code}
        </option>
      );
    } else {
      vals.push(<option value={sa.id}>{sa.code}</option>);
    }
  });
  return <select multiple={multiple} onChange={e => cb(e.target.value)}>{vals}</select>;
};

const SubareaCodeSelectMultiple = props => {
  const { subareas, cb, selected } = props;
  if (!subareas || !Array.isArray(subareas) || subareas.length === 0) {
    console.log("No subareas: " + subareas);
    return "";
  }
  const vals = [];
  subareas.forEach(saCode => {
    if (selected &&  selected.includes(saCode)) {
      vals.push(
        <option value={saCode} selected="selected">
          {saCode}
        </option>
      );
    } else {
      vals.push(<option value={saCode}>{saCode}</option>);
    }
  });
  return (
    <select multiple={true} onChange={e => {
        if (!e.target.value) {
          cb([]);
        } else if (e.target.value.isArray) {
          cb(e.target.value);
        } else {
          cb([e.target.value]);
        }
       }}>
      {vals}
    </select>);
};

const PriceGroupSelect = props => {
  const { priceGroups, cb, selected } = props;
  if (!priceGroups || !Array.isArray(priceGroups)) {
    console.log("No price groups");
  }
  const vals = [];
  vals.push(<option value={null}>{" - "}</option>);
  priceGroups.forEach(pg => {
    if (selected === pg.priceGroupId) {
      vals.push(
        <option value={pg.priceGroupId} title={pg.name} selected="selected">
          {pg.name}
        </option>
      );
    } else {
      vals.push(
        <option value={pg.priceGroupId} title={pg.name}>
          {pg.name}
        </option>
      );
    }
  });
  return (
    <select onChange={e => cb(e.target.value, e.target.title)}>{vals}</select>
  );
};

function setData(cb, data, property, value) {
  console.log(`setData ${property}: ${value}`);
  data[property] = value;
  cb(data);
}

function setSub(cb, data, obj, property, value) {
  console.log(`setSubData ${property}: ${value}`);
  obj[property] = value;
  data.test = Math.random();
  cb(data);
}

export const AddonDetails = props => {
  const { location, editMode, data, seasons, subareas, cb } = props;
  if (!seasons) {
    return <div />;
  }
  const t = key => location.translate(location, key);
  const d = [];
  const common = { t: t, edit: editMode, data: data, cb: cb, obj: data };
  var seasonStr = "";
  seasons.forEach(s => {
    if (s.id === data.seasonId) {
      if (s.name) {
        seasonStr += s.name + ": ";
      }
      seasonStr = s.validFrom + " - " + s.validUntil;
    }
  });

  d.push(
    <tr>
      <Data {...common} title="productCode" property="code" />
      <DataX
        {...common}
        title="productType"
        data={t("ProductType." + data.type)}
        editor={
          <ProductTypeSelect
            l={location}
            cb={v => setData(cb, data, "type", v)}
            addOn={true}
            selected={data.type}
          />
        }
      />
    </tr>
  );
  if (data.type == "PLACE_SEASON_ADDITIONAL" || data.type == "SEASON_EXTRA") {
    d.push(
    <tr>
      <DataX 
      {...common}
      title="mandatoryForNewCustomer"
      data={data.mandatoryForNew ? "X" : "-"}
      editor={
            <input
              type="checkbox"
              checked={data.mandatoryForNew}
              onChange={event =>
                setData(cb, data, "mandatoryForNew", event.target.checked)
              }
            />
        }
      />
      <DataX 
      {...common}
      title="notifySellerOnPayment"
      data={data.mandatoryForNew ? "X" : "-"}
      editor={
            <input
              type="checkbox"
              checked={data.notifySellerOnPayment}
              onChange={event =>
                setData(cb, data, "notifySellerOnPayment", event.target.checked)
              }
            />
        }
      />
      </tr>
    );
  }
  d.push(
    <tr>
      <Data {...common} title="productName" property="description" />
      <DataX
        {...common}
        title="reservationUnit"
        data={t(
          "ProductUnit." +
            (data.reservationUnit ? data.reservationUnit : "DEPOSIT")
        )}
        editor={
          <EnumSelect
            location={location}
            cb={v => setData(cb, data, "reservationUnit", v)}
            filter={v => v !== "LENGTH" && v !== "WIDTH" && v !== "DAY" && (data.type === "SEASON_EXTRA" || v != "MULTIPLE")}
            enumType="ProductUnit"
            selected={data.reservationUnit}
            emptyLabel=""
          />
        }
      />
    </tr>
  );
  d.push(
    <tr>
      <Data {...common} title="basePrice" property="price" float={true} />
      <Data {...common} title="vat" property="vatPercentage" />
    </tr>
  );
  d.push(
    <tr>
      <DataX
        {...common}
        edit={common.edit}
        title="season"
        data={seasonStr}
        editor={
          <SeasonSelect
            seasons={seasons}
            cb={v => setData(cb, data, "seasonId", v)}
            selected={data.seasonId}
          />
        }
      />
      <DataX
        {...common}
        title="pier"
        data={data.subarea}
        editor={
          <SubareaCodeSelectMultiple
            subareas={subareas}
            cb={v => setData(cb, data, "availableSubareas", v)}
            selected={data.availableSubareas}
          />
        }
      />
    </tr>
  );

  return (
    <React.Fragment>
      <table>{d}</table>
    </React.Fragment>
  );
};

const AddonItem = props => {
  const {ao, location, data, cb, common, t, paymentRequest} = props;
  const showAmount = (ao.amount && ao.amountPaid) ? `${ao.amountPaid} / ${ao.amount}` : (ao.amount) ? `${ao.amount}` : (ao.amountPaid) ? `${ao.amountPaid}` :  undefined
  return (<React.Fragment>
      <DataX
        {...common}
        // eslint-disable-next-line prettier/prettier
        edit={common.edit && !(ao.active && ao.reservationUnit === "DEPOSIT")}
        title={ao.description + " " + ao.price + " €"}
        data={showAmount ? showAmount : ao.active ? "X" : "-"}
        editor={
          <React.Fragment>
            {(ao.reservationUnit !== "DEPOSIT" && ao.reservationUnit !== "MULTIPLE") && (
              <input
                type="checkbox"
                disabled={ao.type === "PLACE_SEASON_ADDED"}
                checked={ao.active}
                onChange={event =>
                  setSub(cb, data, ao, "active", event.target.checked)
                }
              />
            )}
            {(ao.reservationUnit === "MULTIPLE") && (
              <input
                type="number"
                min="0"
                style={{width: "50px"}}
                value={ao.amount}
                onChange={event =>
                  setSub(cb, data, ao, "amount", +(event.target.value))
                }
              />
            )}
            {(ao.reservationUnit === "DEPOSIT" || ao.reservationUnit === "MULTIPLE") && (
              <BkModifyButton
                buttonTitle={t("mark-paid-button")}
                callback={() =>
                  genericPut(
                    location.language,
                    "/seasonAdmin/additional/" +
                      ao.productId +
                      "/paid/" +
                      data.placeId,
                      { ...ao, active: true }
                  ).then(r => {
                    if (!r.errorMessage && ao.reservationUnit === "DEPOSIT") {
                      setSub(cb, data, ao, "active", true);
                    } else if (r.errorMessage) {
                      swal(r.errorMessage, { icon: "error"});
                    } else if (r.okMessage) {
                      swal(r.okMessage, { icon: "success"});
                    }
                  })
                }
              />
            )}
            {paymentRequest && (
              <BkModifyButton
                buttonTitle={t("send-payment-button-short")}
                disabled={(ao.active && ao.reservationUnit === "DEPOSIT")}
                callback={() =>
                  genericPut(
                    location.language,
                    "seasonAdmin/additional/" +
                      ao.productId +
                      "/paymentRequest/" +
                      data.placeId,
                      { ...ao, active: true },
                      "booking-web",
                      "application/json",
                      60000
                  ).then(r => {
                    if (r.errorMessage) {
                      swal(r.errorMessage, { icon: "error"});
                    } else if (r.okMessage) {
                      swal(r.okMessage, { icon: "success"});
                    }
                  })
                }
              />
            )}
          </React.Fragment>
        }
      />
  </React.Fragment>);
}

export const PlaceDetails = props => {
  const {
    location,
    editMode,
    simpleMode,
    data,
    seasons,
    season,
    addOn,
    guest,
    subareas,
    cb,
    cancelReservation,
    toggleGuest,
    toggleSeason,
    customerEditable,
    priceGroups,
    emailEditable,
    mobileEditable,
    addonPaymentRequest
  } = props;
  const t = key => location.translate(location, key);

  const d = [];
  const common = { t: t, edit: editMode, data: data, cb: cb, obj: data };
  const prods = !data || !data.products ? [] : data.products;
  let hasSeasonProduct = false;
  prods.forEach(prd => {
    hasSeasonProduct =
      hasSeasonProduct ||
      prd.type === "PLACE_SEASON" ||
      prd.type === "PLACE_SHARE";
  });

  if (season && hasSeasonProduct) {
    const holder = { ...common, obj: data.holder };

    holder.edit = holder.obj && customerEditable && editMode;

    const tdClass = holder.obj
      ? holder.obj.oldSeason
        ? "OLD_SEASON"
        : holder.obj.reservationStatus
      : "FREE";

    d.push(
      <tr>
        <td className={"td-" + tdClass}>Status</td>
        <td colspan="2">{holder.obj.reservationStatusStr}</td>
        <td>{holder.obj.statusTimeStr}</td>
      </tr>
    );
    d.push(<tr />);
    d.push(
      <tr>
        <td colspan="4" className="title">
          {t("holderTitle")}
        </td>
      </tr>
    );
    d.push(
      <tr>
        <Data {...holder} title="report.firstName" property="firstName" />
        <Data {...holder} title="report.lastName" property="lastName" />
      </tr>
    );
    d.push(
      <tr>
        <Data
          {...holder}
          edit={holder.obj.customerId && !emailEditable ? false : holder.edit}
          title="report.email"
          property="email"
        />
        <Data
          {...holder}
          edit={holder.obj.customerId && !mobileEditable ? false : holder.edit}
          title="report.phone"
          property="mobile"
        />
      </tr>
    );
    d.push(
      <tr>
        <Data {...holder} title="address" property="address" />
        <Data {...holder} title="postOffice" property="postOffice" />
      </tr>
    );
    d.push(
      <tr>
        <Data {...holder} title="town" property="town" />
        <DataX
            {...common}
            edit={holder.edit}
            title="language"
            data={data.holder.language}
            editor={
              <LanguageSelect l={location}
                cb={v => setSub(cb, data, data.holder, "language", v)}
                selected={data.holder.language} />
            }
          />
      </tr>
    );
    d.push(
      <tr>
        <Data {...holder} title="boatName" property="boatName" />
        <Data {...holder} title="boatRegister" property="boatRegister" />
      </tr>
    );
    if (editMode) {
      d.push(
        <tr>
          <Data
            {...holder}
            title="boatLength"
            property="boatLength"
            float={true}
          />
          <DataX
            {...common}
            edit={holder.edit}
            title="report.boatType"
            data={data.holder.boatTypeStr}
            editor={
              <BoatTypeSelect
                l={location}
                cb={v => setSub(cb, data, data.holder, "boatType", v)}
                selected={data.holder.boatType}
              />
            }
          />
        </tr>
      );
      d.push(
        <tr>
          <Data
            {...holder}
            title="boatWidth"
            property="boatWidth"
            float={true}
          />
          <Data
            {...holder}
            title="boatDepth"
            property="boatDepth"
            float={true}
          />
        </tr>
      );
    } else {
      d.push(
        <tr>
          <th>{t("boatSize")}</th>
          <td>
            <BoatOrPlaceSize
              width={holder.obj.boatWidth}
              length={holder.obj.boatLength}
              depth={holder.obj.boatDepth}
            />
          </td>
        </tr>
      );
    }
  }
  if (!addOn) {
    d.push(
      <tr>
        <td colspan="4" className="title">
          {t("placeTitle")}
        </td>
      </tr>
    );
    d.push(
      <tr>
        <Data {...common} title="placeCode" property="code" />
        <DataX
          {...common}
          title="placeType"
          data={data.placeTypeStr}
          editor={
            <PlaceTypeSelect
              l={location}
              cb={v => setData(cb, data, "placeType", v)}
              selected={data.placeType}
            />
          }
        />
      </tr>
    );
    if (editMode) {
      d.push(
        <tr>
          <Data
            {...common}
            title="placeLength"
            property="length"
            float={true}
          />
          <Data {...common} title="placeWidth" property="width" float={true} />
        </tr>
      );
      d.push(
        <tr>
          <Data {...common} title="placeDepth" property="depth" float={true} />
          <DataX
            {...common}
            title="pier"
            data={data.subarea}
            editor={
              <SubareaSelect
                subareas={subareas}
                cb={v => setData(cb, data, "subareaId", v)}
                selected={data.subareaId}
              />
            }
          />
        </tr>
      );
    } else {
      d.push(
        <tr>
          <th>{t("placeSize")}</th>
          {data.width || data.length ? (
            <td>
              {data.length && <React.Fragment>{data.length} m</React.Fragment>}
              {data.length && data.width && " x "}
              {data.width && <React.Fragment>{data.width} m</React.Fragment>}
              {data.depth && <React.Fragment> x {data.depth} m</React.Fragment>}
            </td>
          ) : (
            <td />
          )}
          <DataX
            {...common}
            title="pier"
            data={data.subarea}
            editor={
              <SubareaSelect
                subareas={subareas}
                cb={v => setData(cb, data, "subareaId", v)}
                selected={data.subareaId}
              />
            }
          />
        </tr>
      );
    }
    d.push(
      <tr>
        <DataX
          {...common}
          title="multipleProducts"
          data={data.multipleProducts ? "X" : "-"}
          editor={
            <input
              type="checkbox"
              checked={data.multipleProducts}
              onChange={event =>
                setData(cb, data, "multipleProducts", event.target.checked)
              }
            />
          }
        />
      </tr>
    );
  }
  if (!data.placeType && data.placeId) {
    d.push(<CircleLoader />);
  } else if (data.products) {
    const prods = data.products;
    prods.forEach(prd => {
      let contd = true;
      if (
        !addOn &&
        (prd.type === "PLACE_SEASON_ADDED" ||
          prd.type === "PLACE_SEASON_ADDITIONAL" ||
          prd.type === "SEASON_EXTRA")
      ) {
        contd = false;
      } else if (prd.type === "PLACE_GUEST" && !guest) {
        d.push(
          <tr>
            <td colspan="4">
              {prd.typeStr}{" "}
              <BkModifyButton
                buttonTitle={t("show")}
                callback={() => toggleGuest(!guest)}
              />
            </td>
          </tr>
        );
        contd = false;
      } else if (prd.type === "PLACE_SEASON" && !season) {
        d.push(
          <tr>
            <td colspan="4">
              {prd.typeStr}{" "}
              <BkModifyButton
                buttonTitle={t("show")}
                callback={() => toggleSeason(!season)}
              />
            </td>
          </tr>
        );
        contd = false;
      }
      if (contd) {
        d.push(
          <tr>
            <td colspan="4" className="title">
              {t("productsTitle")}: {prd.code}
            </td>
          </tr>
        );
        d.push(
          <tr>
            <Data {...common} title="productCode" property="code" obj={prd} />
            <DataX
              {...common}
              edit={prd.id ? false : common.edit}
              title="productType"
              data={prd.typeStr}
              editor={
                <ProductTypeSelect
                  l={location}
                  addOn={addOn}
                  cb={v => setSub(cb, data, prd, "type", v)}
                  selected={prd.type}
                />
              }
            />
          </tr>
        );
        d.push(
          <tr>
            <Data {...common} title="productName" property="name" obj={prd} />
            <DataX
              {...common}
              edit={simpleMode ? false : common.edit}
              title="season"
              data={prd.season.validFrom + " - " + prd.season.validUntil}
              editor={
                <SeasonSelect
                  seasons={seasons}
                  cb={v => setSub(cb, data, prd.season, "seasonId", v)}
                  selected={prd.season ? prd.season.seasonId : seasons[0].id}
                />
              }
            />
          </tr>
        );
        d.push(
          <tr>
            <Data
              {...common}
              title="basePrice"
              property="price"
              obj={prd}
              float={true}
            />
            <Data {...common} title="vat" property="vatPercentage" obj={prd} />
          </tr>
        );
        if (prd.type === "PLACE_SEASON") {
          if (prd.pricingUnit === "NIGHT") {
            // historically default value was "NIGHT" and this was not selectable/shown for season products...
            prd.pricingUnit = "SEASON"; 
          }
          d.push(
            <tr>
              <DataX
                {...common}
                title="pricingUnit"
                data={t(
                  "ProductUnit." +
                    (prd.pricingUnit ? prd.pricingUnit : "SEASON")
                )}
                editor={
                  <EnumSelect
                    location={location}
                    cb={v => setSub(cb, data, prd, "pricingUnit", v)}
                    filter={v => v === "LENGTH" || v === "SEASON"}
                    enumType="ProductUnit"
                    selected={prd.pricingUnit}
                    emptyLabel=""
                  />
                }
              />
            </tr>
          );
        }
        if (prd.type === "PLACE_GUEST") {
          d.push(
            <tr>
              <DataX
                {...common}
                title="reservationUnit"
                data={t(
                  "ProductUnit." +
                    (prd.reservationUnit ? prd.reservationUnit : "NIGHT")
                )}
                editor={
                  <EnumSelect
                    location={location}
                    cb={v => setSub(cb, data, prd, "reservationUnit", v)}
                    filter={v => v !== "DEPOSIT" && v !== "SEASON"}
                    enumType="ProductUnit"
                    selected={prd.reservationUnit}
                    emptyLabel=""
                  />
                }
              />
              <DataX
                {...common}
                title="pricingUnit"
                data={t(
                  "ProductUnit." + (prd.pricingUnit ? prd.pricingUnit : "NIGHT")
                )}
                editor={
                  <EnumSelect
                    location={location}
                    cb={v => setSub(cb, data, prd, "pricingUnit", v)}
                    enumType="ProductUnit"
                    filter={v => v !== "DEPOSIT" && v !== "SEASON"}
                    selected={prd.pricingUnit}
                    emptyLabel=""
                  />
                }
              />
            </tr>
          );
        }
        const unitsRow2 = [];
        const unitsRow3 = [];
        if (prd.type === "PLACE_GUEST" && prd.pricingUnit !== "DAY" && prd.reservationUnit !== "DAY") {
          unitsRow2.push(
            <DataX
              {...common}
              title="dayPlaceDuringDay"
              data={prd.dayPlaceDuringDay ? "X" : "-"}
              editor={
                <input
                  type="checkbox"
                  checked={prd.dayPlaceDuringDay}
                  onChange={event =>
                    setSub(
                      cb,
                      data,
                      prd,
                      "dayPlaceDuringDay",
                      event.target.checked
                    )
                  }
                />
              }
            />
          );
        }
        if (prd.type === "PLACE_GUEST" && !prd.parkingPlace && prd.pricingUnit !== "DAY" && prd.reservationUnit !== "DAY") {
          d.push(<tr>
              <DataX
                {...common}
                title="parkingIfNoReservation"
                data={prd.parkingIfNoReservation ? "X" : "-"}
                editor={
                  <input
                    type="checkbox"
                    checked={prd.parkingIfNoReservation}
                    onChange={event =>
                      setSub(
                        cb,
                        data,
                        prd,
                        "parkingIfNoReservation",
                        event.target.checked
                      )
                    }
                  />
                }
              /></tr>);
        }
        if (
          prd.pricingUnit === "LENGTH" ||
          prd.pricingUnit === "WIDTH"
        ) {
          unitsRow2.push(
            <Data
              {...common}
              title="minimumPaidUnits"
              property="minimumPaidUnits"
              float={true}
              obj={prd}
            />
          );
        }
        if (prd.reservationUnit === "LENGTH") {
          const minLen = (
            <Data
              {...common}
              title="minimumBoatLength"
              property="minimumBoatLength"
              float={true}
              obj={prd}
            />
          );
          if (unitsRow2.length < 2) {
            unitsRow2.push(minLen);
          } else {
            unitsRow3.push(minLen);
          }
        }
        if (unitsRow2.length > 0) {
          d.push(<tr>{unitsRow2}</tr>);
        }
        if (unitsRow3.length > 0) {
          d.push(<tr>{unitsRow3}</tr>);
        }

        let bookableCb = (
          <DataX
            {...common}
            title="bookable"
            data={prd.bookable ? "X" : "-"}
            editor={
              <input
                type="checkbox"
                title={!prd.price ? "Tuotteella tulee olla hinta" : ""}
                disabled={!prd.price}
                checked={prd.bookable}
                onChange={event =>
                  setSub(cb, data, prd, "bookable", event.target.checked)
                }
              />
            }
          />
        );

        let maxBoatLen = (
          <Data
            {...common}
            title="maximumBoatLength"
            property="maximumBoatLength"
            float={true}
            obj={prd}
          />
        );

        const parkingCb = [];
        if (prd.type === "PLACE_GUEST") {
          parkingCb.push(
            <DataX
              {...common}
              title="parkingPlace"
              data={prd.parkingPlace ? "X" : "-"}
              editor={
                <input
                  type="checkbox"
                  checked={prd.parkingPlace}
                  onChange={event =>
                    setSub(cb, data, prd, "parkingPlace", event.target.checked)
                  }
                />
              }
            />
          );
        }
        d.push(
          <tr>
            <DataX
              {...common}
              title="priceGroup"
              data={prd.priceGroup ? prd.priceGroup.name : "-"}
              editor={
                <PriceGroupSelect
                  l={location}
                  priceGroups={priceGroups}
                  cb={(val, tit) =>
                    // eslint-disable-next-line prettier/prettier
                    setSub(cb, data, prd, "priceGroup", val === "-" ? undefined : { priceGroupId: val, name: tit })
                  }
                  selected={prd.priceGroup ? prd.priceGroup.priceGroupId : null}
                />
              }
            />
            {parkingCb.length === 0 && bookableCb}
            {parkingCb}
          </tr>
        );
        if (parkingCb.length > 0) {
          d.push(<tr>{bookableCb}{maxBoatLen}</tr>);
        } else {
          d.push(<tr>{maxBoatLen}</tr>);
        }

        for (let i = 0; i < prd.addons.length; i += 2) {
          const a1 = prd.addons[i];
          const a2 = prd.addons.length > i ? prd.addons[i + 1] : undefined;

          d.push(
            <tr>
              <AddonItem ao={a1} data={data} common={common} location={location} cb={cb} t={t} paymentRequest={addonPaymentRequest} />
              {a2 && (<AddonItem ao={a2} data={data} common={common} location={location} cb={cb} t={t} paymentRequest={addonPaymentRequest} />)}
            </tr>
          );
        }

        if (season && data.deposits && data.deposits.length) {
          d.push(
            <tr>
              <td colspan="4" className="title">
                {t("depositsTitle")}
              </td>
            </tr>
          );
          data.deposits.forEach(dp => {
            console.log("DP: " + JSON.stringify(dp));
            d.push(
              <tr
                style={{
                  textDecoration: dp.returned ? "line-through" : "none"
                }}
              >
                <td>{dp.productName + " " + dp.price + " €"}</td>
                <td>{dp.customerName}</td>
                <td>{dp.validFrom}</td>
                <td>
                  <BkModifyButton
                    buttonTitle={t("returned-button")}
                    disabled={dp.returned || !editMode}
                    callback={() =>
                      genericPut(
                        location.language,
                        "/seasonAdmin/additional/" +
                          dp.reservationId +
                          "/return",
                        {}
                      ).then(ret => {
                        if (!ret.errorMessage) {
                          setSub(cb, data, dp, "returned", true);
                        }
                      })
                    }
                  />
                </td>
              </tr>
            );
          });
        }

        if (guest && prd.reservations) {
          prd.reservations.forEach(r => {
            d.push(
              <tr>
                <td colspan="4" className="title">
                  {t("reservationsTitle")}: {r.validFrom} - {r.validUntil}
                </td>
              </tr>
            );
            d.push(
              <tr>
                <th>{t("firstName")}</th>
                <td>{r.firstName}</td>
                <th>{t("lastName")}</th>
                <td>{r.lastName}</td>
              </tr>
            );
            d.push(
              <tr>
                <th>{t("email")}</th>
                <td>{r.email}</td>
                <th>{t("mobile")}</th>
                <td>{r.mobile}</td>
              </tr>
            );
            d.push(
              <tr>
                <BkModifyButton
                  buttonTitle={t("free-button")}
                  callback={() => cancelReservation(r)}
                />
              </tr>
            );
          });
        }
      }
    });
  }
  d.push(
    <tr>
      <td colspan="4" className="title">
        {t("commentsTitle")}
      </td>
    </tr>
  );
  d.push(
    <tr>
      <td colspan="4">
        {editMode && <textarea rows={3} style={{width:"100%"}} value={data.internalComment} onChange={event => setData(cb, data, "internalComment", event.target.value)} />}
        {!editMode && <span>{data.internalComment}</span>}
      </td>
    </tr>
  );

  return (
    <React.Fragment>
      <table>{d}</table>
    </React.Fragment>
  );
};
